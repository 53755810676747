import mapping from "../mappings/componentsMapping";


function listFiltersFactory(React, filtersConfig) {
  /*
  "filters": [
          {
            "type": "TextInput",
            "label": "Search",
            "source": "q",
            "alwaysOn": true
          },
          {
            "type": "TextInput",
            "label": "Title",
            "source": "title",
            "alwaysOn": false
          }
        ]
   */
  const filters = filtersConfig
  
  const filterComponents = []
  
  for (const filter of filters) {
    const filterType = mapping[filter.type]
    const filterProps = {
      label: filter.label,
      source: filter.source,
      alwaysOn: filter.alwaysOn
    }
    const newComponent = React.createElement(filterType, filterProps)
    filterComponents.push(newComponent)
  }
  return filterComponents
}

export default listFiltersFactory