import MultiColumnForm from "../components/MultiColumnForm";


/*
// Assumes there will always be one section
multiColumnFormConfig = {
  "spacing": 1,
  "sections": [{
    "count": 2,
    "fields": [
      {
        "type": "TextInput",
        "source": "Title",
        "multiline": true
       },
       {
         "type": "ReferenceInput",
         "source": "UserId",
         "reference": "users",
         "child": {
           "type": "SelectInput",
           "optionText": "name"
         }
       }
    ]
  }]

}
 */


const multiColumnFormFactory = (React, mConfig) => {
  return (props) => {
    return <MultiColumnForm {...mConfig} />
  }
}

export default multiColumnFormFactory

