import {Resource} from "react-admin";
import mapping from '../mappings/componentsMapping'
import listFactory from './listFactory'
import editFactory from "./editFactory";
import {ModalDrawerCreateRoute, ModalDrawerEditRoute} from "./modalDrawerContainerFactory";
import createFactory from "./createFactory";


// returns an array of <Resource> components
function defaultResourcesFactory(React, resourcesConfig) {
    // returns default value if key is undefined
    function getValue(object, key) {
        if (!object) {
            return false
        }
        if (!object[key]) {
            return false
        }
        return object[key]
    }
    
    const resources_components = []

    for (const resource of resourcesConfig) {
        /*
        resource.list = [
              {
                "type": "TextField",
                "source": "id"
              },
              {
                "type": "TextField",
                "source": "name"
              }
            ]
         */

        // build props depending if edit and create actions are set to render as modal or drawer
        let _props = {
            name: resource.name,
            icon: resource.icon && mapping[resource.icon], // optional
            key: resource.name,
            type: resource.type
        }
        
        
        // edit and create has mode
        if (getValue(resource.edit, 'mode') && getValue(resource.create, 'mode')) {
            // console.log(resource.name, 'create and edit has mode')
            _props.list = (props) => {
                return <>
                {resource.list && listFactory(React, resource.list)(props)}
                {ModalDrawerCreateRoute(React, {
                    action: 'create',
                    resourceConfig: resource,
                    resourceFactory: createFactory
                })(props)}
                {ModalDrawerEditRoute(React, {
                    action: 'edit',
                    resourceConfig: resource,
                    resourceFactory: editFactory
                })(props)}
            </>
            }
        }
        // create and edit without mode
        else if (!getValue(resource.create, 'mode') && !getValue(resource.edit, 'mode')) {
            // console.log(resource.name, 'create and edit without mode')
            _props.list = resource.list && listFactory(React, resource.list)
            _props.create = resource.create && createFactory(React, resource.create)
            _props.edit = resource.edit && editFactory(React, resource.edit)
        }
        // create has mode
        else if (getValue(resource.create, 'mode') && !getValue(resource.edit, 'mode')) {
            // console.log(resource.name, 'create has mode')
            _props.list = (props) => {
                return <>
                    {resource.list && listFactory(React, resource.list)(props)}
                    {ModalDrawerCreateRoute(React, {
                        action: 'create',
                        resourceConfig: resource,
                        resourceFactory: createFactory
                    })(props)}
                </>
            }
            _props.edit = resource.edit && editFactory(React, resource.edit)
        }
        // edit has mode
        else if (getValue(resource.edit, 'mode') && !getValue(resource.create, 'mode')) {
            // console.log(resource.name, 'edit has mode')
            _props.list = (props) => {
                return <>
                    {resource.list && listFactory(React, resource.list)(props)}
                    {ModalDrawerEditRoute(React, {
                        action: 'edit',
                        resourceConfig: resource,
                        resourceFactory: editFactory
                    })(props)}
                </>
            }
            _props.create = resource.create && createFactory(React, resource.create)
        }
        //shouldn't reach here
        else {
            // console.error('Mode not handled')
        }
        
        

        if (!resource.list) {
            delete _props.list
        }

        const component = React.createElement(Resource, _props)
        resources_components.push(component)
    }
    return resources_components
}

export default defaultResourcesFactory
