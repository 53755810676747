import {authApiDataProvider} from "./authApiDataProvider";
import {permissionsDataProvider} from "./permissionsDataProvider"
import {loadBiddingApiDataProvider} from "./loadBiddingApiDataProvider"

const dataProviders = new Map([
  ['users', authApiDataProvider],
  ['resources', authApiDataProvider],
  ['resource_groups', authApiDataProvider],
  ['domains', authApiDataProvider],
  ['user_groups', authApiDataProvider],
  ['actions', authApiDataProvider],
  ['auth_metadata', authApiDataProvider],
  ['auth_policies', authApiDataProvider],
  ['auth_models', authApiDataProvider],
  ['sharing_rules', authApiDataProvider],
  ['user_permissions', permissionsDataProvider],
  ['user_group_enrollments', permissionsDataProvider],
  ['shipper_loads', loadBiddingApiDataProvider],
  ['agent_bids', loadBiddingApiDataProvider],
  ['carrier_bids', loadBiddingApiDataProvider],
  ['carrier_bid_items', loadBiddingApiDataProvider],
  ['rate_entries', loadBiddingApiDataProvider],
  ['profit_margins', loadBiddingApiDataProvider],
  ['load_postings', loadBiddingApiDataProvider]
]);

export default async (type, resource, params) => {
  // Get the DataProvider from the map.
  const dataProvider = await dataProviders.get(resource);

  // The DataProvider object is wrapped in a function so we can pass in the parameters to get the result.
  if (dataProvider instanceof Function) {
    return dataProvider(type, resource, params);
  }
};