import {Edit} from 'react-admin';
import simpleFormFactory from "./simpleFormFactory";
import relatedListFactory from "./relatedListFactory";
import multiColumnFormFactory from "./multiColumnFormFactory";


function editFactory(React, editConfig) {
  return props => {
    // console.log('editFactory', editConfig, props)
    return <div>
      <Edit {...props} {...editConfig}>
        {multiColumnFormFactory(React, editConfig)(props)}
      </Edit>
      {editConfig.relatedLists && editConfig.relatedLists.map(listConfig => {
        // console.log('relatedLists', editConfig.relatedLists)
        return relatedListFactory(React, listConfig, editConfig)(props)
      })}
    </div>
  }
}

export default editFactory
