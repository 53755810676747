import React from 'react'
import {Button} from "@material-ui/core";
import {useNotify} from "react-admin";
import {eventHandlerWrapper} from "../utils/componentUtilFunctions";

// actionProps are what gets passed from the config json file
const DefaultButton = actionProps => {
  
  let eventHandlers = actionProps.eventHandlers

  const _use_notify = useNotify()

  if (eventHandlers) {
    eventHandlers.onClickHandler = (!eventHandlers.onClickHandler) ? {options: {}} : eventHandlers.onClickHandler;
    if (eventHandlers.onClickHandler) {
      eventHandlers.onClickHandler.options.notify = _use_notify
      eventHandlers.onClickHandler.options.props = actionProps
    }
  }
  else {
    eventHandlers = [];
  }

  // The button calls the respective function defined in functionName when the button is clicked
  let buttonProps = {}

  for (let handler in eventHandlers) {
    buttonProps[handler.replace('Handler', '')] =
        eventHandlerWrapper(eventHandlers[handler].functionName, eventHandlers[handler].options);
  }

  return <Button {...buttonProps}>{actionProps.text}</Button>
}

export default DefaultButton
