import {Create} from 'react-admin';
import simpleFormFactory from "./simpleFormFactory";
import relatedListFactory from "./relatedListFactory";
import {CreateResourceWithRelated} from "../components/CreateResourceWithRelated";
import multiColumnFormFactory from "./multiColumnFormFactory";


const createFactory = (React, createConfig) => {
    return (props) => {
        if (props.resource === 'carrier_bids') {
            return <div>
            {CreateResourceWithRelated(React, createConfig)(props)}
        </div>
        } else {
            return <div>
                <Create {...props} {...createConfig}>
                    {multiColumnFormFactory(React, createConfig)(props)}
                </Create>
                {/*{CreateResourceWithRelatedContext(React, props, createConfig)}*/}
                {/*{CreateResourceWithRelated(React, props, createConfig)}*/}
                {/*<CarrierBidsCreateWithList/>*/}
                {createConfig.relatedLists && createConfig.relatedLists.map(listConfig => {
                    return relatedListFactory(React, listConfig)(props)
                })}
            </div>
        }
    }
}

export default createFactory
