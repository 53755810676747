import * as React from "react";
import { Route } from 'react-router-dom';
// import loginHandler from "./utils/auth/loginHandler";
import Dashboard from "./components/Dashboard";
import tokenHandler from "./utils/auth/tokenHandler";

const routes = [
    <Route exact path="/token" component={tokenHandler} />,
    // <Route exact path="/user_login" component={loginHandler}/>,
    <Route exact path="/dashboard" component={Dashboard}/>
];

export default routes;