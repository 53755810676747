import {Notification, useLogin, useNotify} from 'react-admin';
import {
  Avatar,
  Box,
  Button, Checkbox,
  Container, createTheme,
  CssBaseline,
  FormControlLabel,
  ThemeProvider,
  Typography
} from "@material-ui/core";
import {theme} from "../../factories/appFactory";


function LockOutlinedIcon() {
  return null;
}

const LoginHandler = (props) => {
  const login = useLogin();
  const notify = useNotify();
  // const classes = useStyles(props.theme);
  const submit = e => {
    e.preventDefault();
    // will call authProvider.login({ email, password })
    login({}).catch(() =>
        notify('Login Failed')
    );
  };
  
  return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs" style={{marginTop: '10%'}}>
          <CssBaseline/>
          <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
          >
            <Typography component="h1" variant="h5">
              Welcome to SPI Admin App
            </Typography>
            {/*<FormControlLabel*/}
            {/*    control={<Checkbox value="remember" color="primary"/>}*/}
            {/*    label="Remember me"*/}
            {/*/>*/}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                sx={{mt: 3, mb: 2}}
                onClick={submit}
                style={{margin: '20px'}}
            >
              Sign In
            </Button>
          </Box>
        </Container>
        <Notification/>
      </ThemeProvider>
  );
}

export default LoginHandler;