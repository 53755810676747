import {default as appConfig} from '../config/app-config.json'

const getResourceAction = (resource, action) => {
    let result;
    for (let resourceConfig of appConfig.resources) {
        if (resource === resourceConfig.name) {
            result = resourceConfig[action]
            break
        }
    }
    return result
}

export default getResourceAction;