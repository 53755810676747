import React from 'react'
import {SimpleForm, TextInput, Edit} from 'react-admin'
import {Accordion, AccordionDetails, AccordionSummary, Grid, TextField} from '@material-ui/core'
import mapping from "../mappings/componentsMapping";
import {ExpandMore} from "@material-ui/icons";

/*
Example props for MultiColumnForm
{
  "spacing": 1,
  "sections": [
    {
      "name": "section1",
      "title": "Section1",
      "count": 2,
      "fields": [
        {
          "type": "TextInput",
          "source": "id"
        },
        {
          "type": "TextInput",
          "source": "name"
        },
        {
          "type": "TextInput",
          "source": "description"
        }
      ],
    },
    {
      "name": "section2",
      "title": "Section2",
      "count": 2,
      "defaultExpanded": false,
      "fields": [
        {
          "type": "TextInput",
          "source": "id"
        },
        {
          "type": "TextInput",
          "source": "name"
        },
        {
          "type": "TextInput",
          "source": "description"
        },
          {
          "type": "TextInput",
          "source": "description2"
        },
          {
          "type": "TextInput",
          "source": "description3"
        },
          {
          "type": "TextInput",
          "source": "description4"
        }
      ]
    }
  ]
}

 */

const CustomGrid = ({basePath, ...props}) => {
  return (
      <Grid {...props} />
  )
}

const MultiColumnForm = props => {
  return <SimpleForm {...props}>
    {props.sections.map((sectionObj) => (
        <Accordion defaultExpanded={sectionObj.defaultExpanded === undefined ? true : sectionObj.defaultExpanded} style={{width: '100%', margin: '10px'}}>
          <AccordionSummary expandIcon={<ExpandMore />}>{sectionObj.title ? sectionObj.title : ''}</AccordionSummary>
          <AccordionDetails>
            <CustomGrid container spacing={props.spacing} style={{width: '100%'}}>
              {sectionObj.fields.map((el) => (
                  <Grid item xs={12 / sectionObj.count}>
                    {React.createElement(mapping[el.type], {source: el.source, style: {width: '100%'}})}
                  </Grid>
              ))}
            </CustomGrid>
          </AccordionDetails>
        </Accordion>
    ))}
  </SimpleForm>
}

export default MultiColumnForm

