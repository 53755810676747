// in src/App.js
import React from 'react';
import appFactory from './factories/appFactory'
import {default as appConfig} from './config/app-config.json'


const App = () => {
  return React.createElement(appFactory(React, appConfig))
};

export default App;
