import mapping from "../mappings/componentsMapping";

export function fieldListFactory(React, fieldListConfig) {
    return fieldListConfig.map((field, index) => {
        const fieldProps = {
            source: field.source,
            key: index,
            label: field.label
        }

        return React.createElement(mapping[field.type], fieldProps)
    });
}