import {CreateContextProvider, useCreateController, useCreate, useNotify, useDataProvider} from "react-admin";
import {Button, Drawer} from "@material-ui/core";
import simpleFormFactory from "../factories/simpleFormFactory";
import React, {useEffect, useState} from "react";
import getResourceAction from "../utils/getResourceAction";
import {ModalDrawerRenderFunction} from "../factories/modalDrawerContainerFactory";


const RelatedListCreateButton = (props) => {
    // console.log('RelatedListCreate props', props)
    // const [create, {loading}] = useCreate(props.resource);
    const [showDialog, setShowDialog] = useState(false);
    // const notify = useNotify();
    const dataProvider = useDataProvider();

    const handleSubmit = (data) => {
        // console.log('submit values', data)
        if (!data) return;
        dataProvider.create(props.resource, {data: data})
            .then(({data}) => {
                // console.log("record created", data)
                setShowDialog(false)
                // window.location.reload()
                props.rerender(prev => prev+1)
                props.useForm().change(`shipper_load_id`, data.id)

            })
            .catch(error => {
                // console.log("failed to create", error)
            })
        props.refresh()
    }
    const _props = {...props}
    _props.save = handleSubmit
    _props.open = showDialog
    _props.onCloseHandler = () => setShowDialog(false)

    return (<><Button onClick={() => setShowDialog(true)}>{props.label}</Button>
        {ModalDrawerRenderFunction(React, {
            action: 'create',
            resourceConfig: { create: getResourceAction(props.resource, props.action)['sections'][0].fields },
            resourceFactory: simpleFormFactory,
            containerType: 'Drawer'
        })(_props)}</>)
}

export default RelatedListCreateButton
