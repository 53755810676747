import React from 'react';
import {Card, CardContent, CardHeader} from '@material-ui/core';

const Dashboard = () => {
    const username = localStorage.getItem('username')
    return <Card>
        <CardHeader title={`Welcome ${username && username} to SPI Admin`}/>
        <CardContent>

        </CardContent>
    </Card>
};

export default Dashboard;