import authConfig from "../config/authConfig";
import { v4 as uuidv4 } from "uuid";

const coreAuthProvider = {
    login: () => {
        // localStorage.removeItem('sessionId');
        // localStorage.removeItem('token');
        const sessionId = uuidv4()
        localStorage.setItem('sessionId', sessionId)
        const params = {
            session_id: sessionId
        }
        const url = `${authConfig.auth_server_url}/app_login?${new URLSearchParams(params)}`
        window.location.replace(url)
        // return Promise.resolve()
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        const sessionId = localStorage.getItem('sessionId');

        if (!sessionId) {
            window.location.replace('/login');
        } else {
            const params = {
                session_id: sessionId
            }
            localStorage.removeItem('sessionId');
            const logout_url = `${authConfig.auth_server_url}/app_logout?${new URLSearchParams(params)}`;
            window.location.replace(logout_url)
        }
        return Promise.resolve()
    },
    // called when the API returns an error
    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: (params) => {
        // console.log('checking auth', params);

        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
}

export {coreAuthProvider};
