import {Admin, createMuiTheme} from "react-admin";
import defaultResourcesFactory from "./resourcesFactory";
import mapping from "../mappings/componentsMapping";
import customRoutes from '../customRoutes'
import createHistory from 'history/createBrowserHistory';
import {Card, colors} from '@material-ui/core';
import LoginPage from "../utils/auth/loginHandler";

export const theme = createMuiTheme({
  palette: {
    type: 'light', // Switching the dark mode on is a single property value change.
    primary: colors.lightBlue,
    secondary: colors.blueGrey
  },
  components: {
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    }
  },
  
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          // backgroundImage: `url("https://paulryan.com.au/wp-content/uploads/2015/01/high-resolution-wallpapers-25.jpg")`,
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover'
        },
      },
    }
  },
});

const history = createHistory();

const appFactory = (React, appConfig) => {
  const _props = {
    authProvider: mapping[appConfig.authProvider], // required
    dataProvider: mapping[appConfig.dataProvider], // required
    dashboard: appConfig.dashboard && mapping[appConfig.dashboard], // optional
    theme: theme,
    customRoutes: customRoutes,
    history: history,
    loginPage: LoginPage
  }
  
  const resourcesConfig = appConfig.resources
  
  return (props) => {
    return <Admin  {..._props}>
      {(localStorage.getItem('token')) ? defaultResourcesFactory(React, resourcesConfig).map(el => el) :
          <Card></Card>}
    </Admin>
  }
}

export {history}
export default appFactory
