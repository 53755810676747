import {Datagrid, List} from "react-admin"
import mapping from "../mappings/componentsMapping";
import listFiltersFactory from './listFiltersFactory'
import {fieldListFactory} from "./fieldListFactory";
import React from "react";
import {CardActions} from "@material-ui/core";
import actionFactory from "./actionsFactory";


const listFactory = (React, listConfig) => {
  /*
  listConfig = {
        "filters": "postFilters",
        "fields": [
          {
            "type": "TextField",
            "source": "id"
          },
          {
            "type": "TextField",
            "source": "name"
          }
        ]
      }
   */

  // if json defines key 'customType', it uses the user defined component.
  // else, it returns <List> defined by react-admin
  if (listConfig.customType) {
    return mapping[listConfig.customType]
  }

  const ListActions = () => {
    return <CardActions>
      {listConfig.listActions && listConfig.listActions.map((el) => actionFactory(React, el))}
    </CardActions>
  }

  const filters = listFiltersFactory(React, listConfig.filters)
  return props => <List {...props} filters={filters} actions={<ListActions/>} perPage={25}>
    <Datagrid rowClick="edit">
      {fieldListFactory(React, listConfig.fields)}
    </Datagrid>
  </List>
}

export default listFactory
