import UserIcon from "@material-ui/icons/Group"
import {coreAuthProvider} from "../authProviders/coreAuthProvider"
import dataProviderMapper from "../dataProviders/dataProviderMapper"
import Dashboard from "../components/Dashboard"
import {
    ReferenceField,
    TextField,
    TextInput,
    SelectInput,
    ReferenceInput,
    EditGuesser,
    AutocompleteInput, ExportButton, BooleanInput, BooleanField
} from "react-admin"
import DefaultEditButton from "../components/DefaultEditButton";
import DefaultButton from '../components/DefaultButton'
import DefaultCreateButton from "../components/DefaultCreateButton";
import DefaultCreateRelatedButton from "../components/DefaultCreateRelatedButton";
import {Drawer, Modal} from "@material-ui/core";
import RelatedListCreateButton from '../components/RelatedListCreateButton'


// Mapping is used to map between a json config(string) and js objects
const mapping = {
    'UserIcon': UserIcon,
    'authProvider': coreAuthProvider,
    'superDataProvider': dataProviderMapper,
    'Dashboard': Dashboard,
    'TextField': TextField,
    'ReferenceField': ReferenceField,
    'TextInput': TextInput,
    'ReferenceInput': ReferenceInput,
    'SelectInput': SelectInput,
    'DefaultEditButton': DefaultEditButton,
    'DefaultButton': DefaultButton,
    'EditGuesser': EditGuesser,
    'AutocompleteInput': AutocompleteInput,
    'DefaultCreateButton': DefaultCreateButton,
    'DefaultCreateRelatedButton': DefaultCreateRelatedButton,
    'Modal': Modal,
    'Drawer': Drawer,
    'ExportButton': ExportButton,
    'RelatedListCreateButton': RelatedListCreateButton,
    'BooleanField': BooleanField,
    'BooleanInput': BooleanInput
}

export default mapping;
