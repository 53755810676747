import {Datagrid, ReferenceManyField, useDataProvider, useRefresh} from 'react-admin'
import mapping from "../mappings/componentsMapping";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
import actionFactory from "./actionsFactory";
import {ExpandMore} from "@material-ui/icons";



const relatedListFactory = (React, relatedListConfig) => {
    return (props) => {
        const refresh = useRefresh();
        const dataProvider = useDataProvider();
        let record_data;

        const title = relatedListConfig.resource
        // check for mode in edit and create

      return <Accordion defaultExpanded style={{marginTop: '40px'}}>
        <AccordionSummary expandIcon={<ExpandMore />}><Typography variant="h5" gutterBottom>{title}</Typography></AccordionSummary>
        <AccordionDetails style={{flexDirection: 'column'}}>
                <div style={{marginTop: "30px", marginBottom: "20px"}}>

                    {relatedListConfig.listActions && relatedListConfig.listActions.map(action => {
                        // rendering buttons
                        action.props.resource = relatedListConfig.resource
                        action.props.record = {}
                        action.props.refresh = refresh

                        dataProvider.getOne(props.resource, {id: props.id})
                            .then(({data}) => {
                                // console.log("In getOne in actionMap", data)
                                record_data = data
                                action.props.record[action.props.targetResourceKey] = record_data[relatedListConfig.targetResourceKey]
                            })

                        return actionFactory(React, action)
                    })}
                </div>
                <ReferenceManyField
                    addLabel={true}
                    reference={relatedListConfig.resource}
                    target={relatedListConfig.target}
                    sort={{field: "user", order: "DESC"}}
                >
                    <Datagrid title={relatedListConfig.resource}>
                        {relatedListConfig.fields.map((field, index) => {
                            const componentType = mapping[field.type]
                            return React.createElement(componentType, {source: field.source, key: index})
                        })}
                        {relatedListConfig.actions.map(action => {
                            // rendering buttons
                            return actionFactory(React, action)
                        })}
                    </Datagrid>
                </ReferenceManyField>
            </AccordionDetails>
      </Accordion>
    }
}

export default relatedListFactory;