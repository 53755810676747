import {history} from "./appFactory";
import {Route} from 'react-router';
import mapping from '../mappings/componentsMapping'


function handleClose() {
    history.go(-1)
}

const ModalDrawerRenderFunction = (React, modalDrawerConfig) => {
    // console.log('modal drawer render func')

    return (props) => {
        let typeStr
        if (!!modalDrawerConfig.containerType) {
            typeStr = modalDrawerConfig.containerType
        }
        else if (!!modalDrawerConfig.resourceConfig[modalDrawerConfig.action]) {
            typeStr = modalDrawerConfig.resourceConfig[modalDrawerConfig.action].mode
        }
        const type = mapping[typeStr]

        // If a mode of render is specified, we use it:
        return React.createElement(type, {
                onClose: !!props.onCloseHandler ? props.onCloseHandler : handleClose,
                open: props.open,
                style: {display: 'flex', alignItems: 'center', justifyContent: 'center'}
            }, modalDrawerConfig.resourceFactory(React, modalDrawerConfig.resourceConfig[modalDrawerConfig.action])(props))
    }
}

const ModalDrawerCreateRoute = (React, modalDrawerConfig) => {
    // console.log('modal drawer create route')
    return (props) => {
        // console.log('create route', props)
        const route_path = `/${modalDrawerConfig.resourceConfig.name}/${modalDrawerConfig.action}`
        // console.log(route_path)
        return <Route path={route_path}>
            {({match}) => {
                const _props = {...props}
                _props.open = !!match
                return ModalDrawerRenderFunction(React, modalDrawerConfig)(_props)
            }
            }
        </Route>
    }
}

const ModalDrawerEditRoute = (React, modalDrawerConfig) => {
    // console.log('modal drawer edit route')
    return (props) => {
        const route_path = `/${modalDrawerConfig.resourceConfig.name}/:id`
        return <Route path={route_path}>
            {({match}) => {
                const isMatch = match && match.params && match.params.id !== "create";
                const _id = props.location.pathname.split('/').pop()
                const _props = {...props}
                _props.open = !!match
                _props.id = _id
                return isMatch ? ModalDrawerRenderFunction(React, modalDrawerConfig)(_props) : null
            }}
        </Route>
    }
}


export {ModalDrawerEditRoute, ModalDrawerCreateRoute, ModalDrawerRenderFunction}