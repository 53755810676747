import {SimpleForm} from 'react-admin';
import mapping from "../mappings/componentsMapping";


const simpleFormFactory = (React, simpleFormConfig) => {
  /*
  simpleFormConfig = [
    {
      "type": "TextInput",
      "source": "Title",
      "multiline": true
     },
     {
       "type": "ReferenceInput",
       "source": "UserId",
       "reference": "users",
       "child": {
         "type": "SelectInput",
         "optionText": "name"
       }
     }
  ]
   */

  return (props) => {
    const simpleFormChildren = []
    for (const config of simpleFormConfig) {
      const simpleFormProps = config

      // TODO add default values for reference fields
      let child = null
      if (config.child) {
        const childProps = {
          optionText: config.child.optionText,
          optionValue: config.child.optionValue
        }
        child = React.createElement(mapping[config.child.type], {...childProps, ...config.child})
      }

      const component = React.createElement(mapping[config.type], simpleFormProps, child)
      simpleFormChildren.push(component)


    }
    const _props = {...props}
    // if (onSubmitHandler) {
    //   // console.log(onSubmitHandler)
    //   _props['onSubmit'] = onSubmitHandler
    // }

    return <SimpleForm {..._props}>
      {simpleFormChildren.map(el => el)}
    </SimpleForm>
  }
}

export default simpleFormFactory
