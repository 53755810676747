import React from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core"
import {useDataProvider} from "react-admin"


const fieldProps = [
    {
        label: "Charge Type",
        field: "charge_type",
        defaultValue: "Line Haul",
        size: "small",
        variant: "filled"
    },
    {
        label: "Amount",
        field: "amount",
        defaultValue: 0,
        type: "number",
        size: "small",
        variant: "outlined"
    },
    {
        label: "Currency",
        field: "currency",
        defaultValue: "USD",
        size: "small",
        variant: "outlined"
    }
]

const parentProps = {
    record: {id: 1, owner_username: 'test_user', 'carrier_bid_items': []},
    resource: "carrier_bids",
    resourceName: "Carrier Bids",
    relatedResourceName: "Items",
    relationshipField: "carrier_bid_items",
    fields: [
        {
            source: "id",
            "label": "ID"
        }
    ]
}

const _addRelatedObject = (RelatedRecord, record, RelatedItems, parentUpdaterFunction, relatedUpdaterFunction) => {
    let newRelatedRecord = {};
    newRelatedRecord[parentProps.relationshipField] = [...record[parentProps.relationshipField], RelatedRecord]
    relatedUpdaterFunction([...RelatedItems, ...newRelatedRecord[parentProps.relationshipField]]);
    parentUpdaterFunction({...record, ...newRelatedRecord});
};


const sum = objArray => objArray.reduce((a, b) => (+a + +b), 0);


const RelatedRecord = ({RelatedObject, index, deleteRelatedObject}) => {
    return (
        <TableRow key={index}>
            {Object.values(RelatedObject).map((item) => (
                    <TableCell>
                        {item}
                    </TableCell>
                )
            )}
            <TableCell>
                <Button variant={"contained"} color={"primary"}
                        onClick={() => deleteRelatedObject(index)}>Remove</Button>
            </TableCell>
        </TableRow>
    )
};


const RelatedRecordInput = (props) => {
    const [value, setValue] = React.useState(props.defaultValue);

    const handleChange = e => {
        setValue(e.target.value)
        props.parentUpdater(props.field, e.target.value)
    }

    return <TextField
        id="outlined-size-small"
        label={props.label}
        type={props.type}
        defaultValue={props.defaultValue}
        variant={props.variant}
        size={props.size}
        value={value}
        onChange={handleChange}
    />
}


const AddRelatedRecordForm = ({addRelatedObject, fieldProps}) => {
    let initialValues = {}
    fieldProps.map((item) => (initialValues[item.field] = item.defaultValue))

    const [relatedRecord, setRecord] = React.useState(initialValues);
    const handleSubmit = e => {
        e.preventDefault();
        if (!relatedRecord) return;
        addRelatedObject(relatedRecord);
    };

    const setRecordField = (field, value) => {
        let updateObject = {}
        updateObject[field] = value
        const newObject = {...relatedRecord, ...updateObject}
        setRecord(newObject)
    }

    const _fieldProps = fieldProps.map((item) => {
        return {...item, ...{parentUpdater: setRecordField}}
    })

    return (
        <form onSubmit={handleSubmit}>
            <Box>
                <TableRow>
                    {_fieldProps.map((item) => (
                        <TableCell>
                            <RelatedRecordInput {...item}/>
                        </TableCell>
                    ))}
                    <TableCell>
                        <Button color="primary" variant="contained" onClick={handleSubmit}>Add</Button>
                    </TableCell>
                </TableRow>

            </Box>
        </form>
    );
}


const RelatedObjectsTable = (props) => {
    return <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    {props.fieldProps.map((item) => (
                        <TableCell>{item.label}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.record[parentProps.relationshipField].map((item, index) => (
                    <RelatedRecord RelatedObject={item} index={index} deleteRelatedObject={props.deleteRelatedObject}/>
                ))}
            </TableBody>
        </Table>
    </TableContainer>;
}


const CreateResourceWithRelated = (React, createConfig) => {
    const [record, setRecord] = React.useState(parentProps.record);
    const [RelatedItems, setRelatedItems] = React.useState([])
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();
    const dataProvider = useDataProvider();

    const addRelatedObject = (RelatedRecord) => _addRelatedObject(RelatedRecord, record, RelatedItems, setRecord, setRelatedItems)

    const deleteRelatedObject = index => {
        let newRelatedRecord = {}
        newRelatedRecord[parentProps.relationshipField] = [...record[parentProps.relationshipField]]
        newRelatedRecord[parentProps.relationshipField].splice(index, 1);
        setRelatedItems([...newRelatedRecord[parentProps.relationshipField]])
        setRecord({...record, ...newRelatedRecord});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!record) return;
        dataProvider.create(parentProps.resource, {data: record})
            .then(({data}) => {
                // console.log("record created", data)
                setLoading(false);
            })
            .catch(error => {
                // console.log("failed to create", error)
                setError(error);
                setLoading(false);
            })
    };

    return (props) => {
        return (
            <form>
                <Box>
                    <Card>
                        <CardContent>
                            <Typography>
                                {parentProps.resourceName}
                            </Typography>
                            <TableRow>
                                {parentProps.fields.map((item) => (
                                    <TableCell>
                                        <TextField value={record[item.source]} label={item.label} variant={"outlined"}
                                                   size={"small"} margin={"dense"}/>
                                    </TableCell>
                                ))}
                                <TableCell>
                                    <TextField
                                        value={sum(record[parentProps.relationshipField].map((item) => item.amount))}
                                        variant={"outlined"}
                                        size={"small"}
                                        margin={"dense"}
                                        label={"Total"}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button color="primary" variant="contained" onClick={handleSubmit}>Save</Button>
                                </TableCell>
                            </TableRow>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <Typography>
                                Add {parentProps.relatedResourceName}
                            </Typography>
                            <AddRelatedRecordForm addRelatedObject={addRelatedObject} fieldProps={fieldProps}/>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <Typography>
                                {parentProps.relatedResourceName} List
                            </Typography>
                            <RelatedObjectsTable record={record} fieldProps={fieldProps}
                                                 deleteRelatedObject={deleteRelatedObject}/>
                        </CardContent>
                    </Card>
                </Box>
            </form>
        )
    }
}


export {CreateResourceWithRelated}