import {httpClient, RestProvider} from './coreApiDataProvider';
import {getDataProviderMap} from "../utils/getDataProviderMap";
import {dataProviderConfig} from "../config/dataProvidersConfig";

const authApiRestProvider = (apiUrl) => {
    const authApiProvider = {
        create: function (resource, params) {
            // console.log('create called', resource, params)
            let url
            let payload
            url = `${apiUrl}/permissions`
            payload = [params.data]

            return httpClient(url, {method: 'POST', body: JSON.stringify(payload)}).then(({json}) => ({
                    data: json
                })
            );
        }
        ,
        getOne: function (resource, params) {
            // console.log('get one called', resource, params);

            let url

            const policy_name = params.id.split(':')[0]
            const identifierValue = params.id.split(':')[1]
            // console.log('policy_name', policy_name, 'identifier_value', identifierValue)

            const entity = {
                "source_extraction_params": {
                    "policy_name": policy_name,
                }
            }
            url = `${apiUrl}/permissions/${identifierValue}?entity=${encodeURI(JSON.stringify(entity))}`

            let result

            if (resource === 'user_group_enrollments' || resource === 'user_permissions') {
                result = httpClient(url).then(
                    ({json}) => ({
                        data: {...json, id: `${json.policy_name}:${json.id}`},
                        total: json.length
                    })
                );
            } else {
                result = httpClient(url).then(
                    ({json}) => ({
                        data: json,
                        total: json.length
                    })
                );
            }

            // console.log('permissionsDataProvider', 'getOne result', result)
            return new Promise((resolve, reject) => {
                resolve(result)
                reject("error")
            })


        }
        ,
        getManyReference: function (resource, params) {
            // console.log('get many resource', resource, params)
            const currentUrl = window.location.href;
            const identifierValue = currentUrl.split("/").pop();
            // console.log('permissionsDataProvider', identifierValue)
            const url = `${apiUrl}/${resource}/${identifierValue}`

            let result

            if (resource === 'user_group_enrollments' || resource === 'user_permissions') {
                // console.log(url)
                result = httpClient(url).then(
                    ({json}) => ({
                        data: json.map(resource => ({...resource, id: `${resource.policy_name}:${resource.id}`})),
                        total: json.length
                    })
                );
            } else {
                result = httpClient(url).then(
                    ({json}) => ({
                        data: json,
                        total: json.length
                    })
                );
            }
            // console.log('getManyReference result', result)

            return result
        }
        ,
        update: function (resource, params) {
            // console.log('update one called', resource, params);
            let identifier
            if (params.id.includes(':')) {
                identifier = params.id.split(':')[1]
                params.data.id = identifier
            } else {
                identifier = params.id
            }
            const url = `${apiUrl}/permissions/${identifier}`

            const result = httpClient(url, {method: 'PATCH', body: JSON.stringify(params.data)}).then(({json}) => ({
                    data: json
                })
            );

            return new Promise((resolve, reject) => {
                resolve(result)
                reject("error")
            })


        }
    }
    return Object.assign(RestProvider(apiUrl), authApiProvider);

}

export const permissionsDataProvider = (type, resource, params) => {
    const apiUrl = dataProviderConfig.permissionsDataProvider;
    const provider = authApiRestProvider(apiUrl);
    const providerMap = getDataProviderMap(provider, resource, params);
    return providerMap.get(type)(resource, params);
};
